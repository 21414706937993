// form-helper.service.ts

import { ElementRef, Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
@Injectable({
  providedIn: 'root' // Provide the service at the root level
})
export class FormValidationService {
  constructor() { }

  /**
   * validateAllFormFields
   */
  public validateAllFormFields(form: any, formElement: ElementRef) {
    Object.keys(form.controls).forEach((field) => {
      const control = form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
        const invalidFields = Object.keys(form.controls).filter((field) => {
          const control = form.get(field);
          return control?.invalid && (control?.dirty || control?.touched);
        });
        if (invalidFields.length > 0) {
          this.scrollAndFocusControl(formElement, invalidFields[0]);
        }
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control, formElement);
      }
    });
  }
  scrollAndFocusControl(formElement: ElementRef, controlName: string) {
    const invalidControl = formElement.nativeElement.querySelector(
      `[formcontrolname="${controlName}"]`
    );

    if (invalidControl) {
      invalidControl.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
        duration: 1000
      });
      invalidControl.focus();
    }
  }

  /**
   * validateAllFormFieldsWithoutScroll
   */
  public validateAllFormFieldsWithoutScroll(form: any) {
    Object.keys(form.controls).forEach((field) => {
      const control = form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFieldsWithoutScroll(control);
      }
    });
  }
}
