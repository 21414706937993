import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { AppSettingsService } from 'src/app/core/app-settings.service';
import { parseResponse } from 'src/app/core/helpers';
import { Menu, QueryResultsModel } from '../../../core/module/menu-interface';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class MenusService {
  constructor(
    private http: HttpClient,
    private appSettingsService: AppSettingsService
  ) {}

  getMenus(queryParams: any): Observable<QueryResultsModel> {
    let params = this.appSettingsService.queryStrFormat(queryParams);

    return this.http.get('menu?' + params, httpOptions).pipe(
      map((resp: any) => {
        let retData: any = {
          items: [],
          totalCount: 0,
          error: ''
        };

        if (
          resp &&
          resp.data &&
          Array.isArray(resp.data.rows) &&
          resp?.error?.code <= 0
        ) {
          let rows = resp.data.rows;
          for (const item of rows) {
            let temp: any = {
              ...item
            };
            retData.items.push(temp);
          }

          retData.totalCount = resp.data.count;
        } else {
          retData.error = resp?.error?.message ? resp.error.message : '';
        }

        return retData;
      })
    );
  }

  createMenu(data: Menu): Observable<any> {
    return this.http.post('menu', data, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  deleteMenu(id: any): Observable<any> {
    return this.http.delete('menu/' + id, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }
  updateMenu(id: any, data: Menu): Observable<any> {
    return this.http.put('menu/' + id, data, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  getAllMenus(params: any) {
    console.log(params);
    let param = this.appSettingsService.queryStringFormatWithoutOffset(params);
    return this.http.get('menu?' + param, httpOptions).pipe(
      map((resp: any) => {
        let retData: any = {
          items: [],
          totalCount: 0,
          error: ''
        };

        if (
          resp &&
          resp.data &&
          Array.isArray(resp.data.rows) &&
          resp?.error?.code <= 0
        ) {
          let rows = resp.data.rows;
          for (const item of rows) {
            let temp: any = {
              id: parseInt(item.id),
              name: item.name,
              slug: item.slug,
              menuActions: item.menuActions.map((action: any) => ({
                id: parseInt(action.id),
                name: action.name,
                isDelete: action.isDelete
              }))
            };

            // Check if menuActions is not empty before pushing temp
            if (temp.menuActions.length > 0) {
              retData.items.push(temp);
            }
          }

          retData.totalCount = resp.data.count;
        } else {
          retData.error = resp?.error?.message ? resp.error.message : '';
        }

        return retData;
      })
    );
  }

  onGetCQAMenusActions() {
    return [
      {
        dashboard: [
          { name: 'View' },
          { name: 'Download Forms' },
          { name: 'Fill-in/Submit Forms' },
          { name: 'Upload Supporting Docs/Info' }
        ],
        company: [
          { name: 'View Company details' },
          { name: 'Create Company' },
          { name: 'Edit Company ' },
          { name: 'Review' },
          { name: 'Apporve' },
          { name: 'Reject Comapy Registration' }
        ],
        additive_company_contacts: [{ name: 'View' }],
        licensee_company_contacts: [{ name: 'View' }],
        license_agreements_amendments: [{ name: 'View' }],
        completed_forms_documents: [{ name: 'View' }],
        submitted_formulations: [{ name: 'View' }],
        formulation_data: [{ name: 'View' }],
        submitted_samples: [{ name: 'View' }],
        sample_data: [{ name: 'View' }],
        brand: [{ name: 'View' }],
        sample_data_reports: [{ name: 'View' }],
        product_brand_names: [{ name: 'View' }],
        product_labeling: [{ name: 'View' }],
        marketing_materials: [{ name: 'View' }],
        brand_registration_certificates: [{ name: 'View' }],
        website_brands_list: [{ name: 'View' }],
        renewal: [{ name: 'View' }],
        expiration_termination_notices: [{ name: 'View' }],
        production_sample_intake_tracking: [{ name: 'View' }],
        field_audit_sample_selection_generator: [{ name: 'View' }],
        licensing_program_fees: [{ name: 'View' }],
        quotes: [{ name: 'View' }],
        invoices: [{ name: 'View' }],
        financial_data: [{ name: 'View' }],
        financial_reports: [{ name: 'View' }],
        users: [{ name: 'View' }],
        programBuilder: [{ name: 'View' }],
        menus: [{ name: 'View' }],
        roles: [{ name: 'View' }],
        test_method: [{ name: 'View' }],
        unitOfMeasure: [{ name: 'View' }],
        test: [{ name: 'View' }],
        testGroup: [{ name: 'View' }],
        laboratory: [{ name: 'View' }],
        domainWhiteList: [{ name: 'View' }],
        processFlow: [{ name: 'View' }],
        sampleType: [{ name: 'View' }],
        viscosityGrade: [{ name: 'View' }],
        specification: [{ name: 'View' }],
        fileManagement: [{ name: 'View' }],
        sample: [{ name: 'View' }],
        brands: [{ name: 'View' }],
        payments: [{ name: 'View' }],
        license: [{ name: 'View' }],
        formulation: [{ name: 'View' }],
        configuration_company: [{ name: 'View' }],
        configuration_users: [{ name: 'View' }],
        configuration_forms: [{ name: 'View' }],
        configuration_quotations: [{ name: 'View' }],
        configuration_reports: [{ name: 'View' }],
        configuration_process: [{ name: 'View' }]

        // license_agreement: [
        //   { name: 'View' },
        //   { name: 'Download Submitted Forms' },
        //   { name: 'Upload Submitted Forms' }
        // ],
        // _company: [
        //   { name: 'View' },
        //   { name: 'Download Test Data' },
        //   { name: 'Approve Test Data/Samples' },
        //   { name: 'DeletGenerate List for Sample Collectione' },
        //   { name: 'Execute & Issue Docs' }
        // ],
        // payments: [
        //   { name: 'View' },
        //   { name: 'Issue Payment Credits' },
        //   { name: 'View Receipts, Expenses, Disbursements' }
        // ],
        // product_registration: [
        //   { name: 'View' },
        //   { name: 'Download Active Licenses' },
        //   { name: 'Download Inactive Licenses' },
        //   { name: 'Download In-Process Licenses' },
        //   { name: 'Download Product Promos' }
        // ],
        // staff: [
        //   { name: 'View' },
        //   { name: 'Edit' },
        //   { name: 'Add' },
        //   { name: 'Delete' }
        // ],
        // communication: [
        //   { name: 'Communicate with CQA' },
        //   { name: 'Communicate with Client/Sponsors' },
        //   { name: 'Communicate with Licensees' },
        //   { name: 'Communicate with Formulators' }
        // ],
        // users: [
        //   { name: 'View' },
        //   { name: 'Edit' },
        //   { name: 'Reset Passwords' },
        //   { name: 'Grant Permissions' }
        // ],
        // audit_management: [
        //   { name: 'Upload Sample, Audit, Financial Data' },
        //   { name: 'Download Completed Audit Info' },
        //   { name: 'Download In-Process Audit Info' }
        // ],

        // reports: [
        //   { name: 'View' },
        //   { name: 'Download Data System-Wide' },
        //   { name: 'Create Report' },
        //   { name: 'Send Reports' }
        // ],
        // program: [
        //   { name: 'View' },
        //   { name: 'Create' },
        //   { name: 'Modify' },
        //   { name: 'Delete' }
        // ],
        // static_content: [
        //   { name: 'View' },
        //   { name: 'Create' },
        //   { name: 'Modify' },
        //   { name: 'Delete' }
        // ],
        // forms: [
        //   { name: 'View', action: 'view' },
        //   { name: 'Create' },
        //   { name: 'Modify' },
        //   { name: 'Delete' }
        // ],
        // about: [],
        // sample_company: [
        //   { name: 'View', action: 'view' },
        //   { name: 'Create' },
        //   { name: 'Modify' },
        //   { name: 'Delete' }
        // ],
        // dashboard_company: [
        //   { name: 'View' },
        //   { name: 'Fill-in/Submit Forms' },
        //   { name: 'Upload Supporting Docs/Info' }
        // ],
        // forms_company: [
        //   { name: 'Download Forms' },
        //   { name: 'Fill-in/Submit Forms' }
        // ],
        // master_data: [
        //   { name: 'Roles' },
        //   { name: 'Test Method' },
        //   { name: 'Unit of Measure' },
        //   { name: 'Test' },
        //   { name: 'Test Group' },
        //   { name: 'Laboratory' },
        //   { name: 'File Management' },
        //   { name: 'Domain Whitelist' },
        //   { name: 'Process Flow ' },
        //   { name: 'Sample Type ' },
        //   { name: 'Program Builder ' }
        // ],
        // team: [
        //   { name: 'View' },
        //   { name: 'Create Team Member' },
        //   { name: 'Delete Team Member' },
        //   { name: 'Modify Team Member' }
        // ]
      }
    ];
  }
  onGetCompanyMenusActions() {
    return [
      {
        about: [{ name: 'View' }],
        sample_company: [
          { name: 'View', action: 'view' },
          { name: 'Download Test Data' },
          { name: 'Download Completed Sample Collections' },
          { name: 'Download  In-process Audit Info' }
        ],
        dashboard_company: [
          { name: 'View' },
          { name: 'Fill-in/Submit Forms' },
          { name: 'Upload Supporting Docs/Info' },
          { name: 'Submit a New Formulation for Approval' },
          { name: 'View Formulation Approval Status' },
          { name: 'Submit a Qualification Sample' },
          { name: 'View Qualification Sample Testing Status' },
          { name: 'View / Download Completed Test Data Reports' },
          { name: 'Register a New Brand Name' },
          { name: 'Review / Revise Registered Brand Names' },
          { name: 'Submit Product Labels for Approval' },
          { name: 'Review Approved Labels' },
          { name: 'Renew Approved Formulations and Registered Brand Names' },
          { name: 'Submit a Production Sample' },
          { name: 'View Production Sample Testing Status' },
          { name: 'Licensing Program Policies' },
          { name: 'Completed Licensing Documents' },
          { name: 'Approved Logos and Marketing Materials' },
          { name: 'Payment Center' },
          { name: 'Profile Management' },
          { name: ' View Qualification Sample Testing Status' },
          { name: 'Teams' },
          { name: 'Templates' },
          { name: 'Quotations' }
        ],
        forms_company: [
          { name: 'Download Forms' },
          { name: 'Fill-in/Submit Forms' }
        ],
        payments_company: [
          { name: 'View Payments and Payment Status' },
          { name: 'Issue Payments Credits' },
          { name: 'View Receipts ' }
        ],
        team_company: [
          { name: 'View' },
          { name: 'Create Team Member' },
          { name: 'Delete Team Member' },
          { name: 'Modify Team Member' }
        ]
      }
    ];
  }

  preparePerMissions(resp: any, userId: number) {
    const permissionsData: any = resp;
    // Create an object to store permissions grouped by menuID
    const groupedPermissions: any = {};
    let roleName: any;
    let menuRoleId: any;
    let userID = userId;

    if (permissionsData && permissionsData.length > 0) {
      // Group permissions by menuID
      permissionsData.forEach((permission: any) => {
        const menuID = permission.menu?.id;
        roleName = permission.menuRole?.name;
        menuRoleId = permission.menuRole?.id;
        if (!groupedPermissions[menuID]) {
          // Initialize an array for menuActions if it doesn't exist
          groupedPermissions[menuID] = {
            id: parseInt(menuID),
            name: permission.menu?.name,
            menuActions: []
          };
        }
        // Add menuAction to the array
        groupedPermissions[menuID].menuActions.push({
          id: parseInt(permission.menuAction?.id),
          name: permission.menuAction?.name,
          isDelete: permission.menuAction?.isDelete,
          permissionId: parseInt(permission.id)
        });
      });
    }

    // Convert the grouped permissions object into an array
    const transformedData: any = {
      roleName: roleName,
      menuRoleId: menuRoleId,
      userID: userID,
      items: Object.values(groupedPermissions)
    };
    return transformedData;
  }
  getSelectedMenuValuesEdit(menuList: any, roles_id: any) {
    let newObjects: any = [];
    menuList.forEach((menu: any) => {
      menu.menuActions.forEach((action: any) => {
        console.log(action);
        if (action.is_there == true && !action.selectedEditMenu) {
          const newObj: any = {
            menuID: menu.id,
            menuActionId: action.id,
            isDelete: false
          };
          if (action.permissionId) {
            newObj['permissionId'] = parseInt(action.permissionId);
          }
          if (parseInt(roles_id)) {
            newObj['menuRoleId'] = parseInt(roles_id);
          }
          newObjects.push(newObj);
        } else if (action.is_there == false && action.permissionId) {
          const newObj: any = {
            permissionId: parseInt(action.permissionId),
            isDelete: true
          };
          newObjects.push(newObj);
        }
      });
    });
    return newObjects;
  }
}
