import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { AppSettingsService } from 'src/app/core/app-settings.service';
import { parseResponse } from 'src/app/core/helpers';
import { environment } from 'src/assets/environments/environment.prod';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class ProgramBrandingService {
  constructor(
    private http: HttpClient,
    private appSettingsService: AppSettingsService
  ) {}

  getBrandingList(queryParams: any) {
    let params = this.appSettingsService.queryStrFormat(queryParams);

    return this.http.get(`brand?` + params, httpOptions).pipe(
      map((resp: any) => {
        let retData: any = {
          items: [],
          totalCount: 0,
          error: ''
        };

        if (
          resp &&
          resp.data &&
          Array.isArray(resp.data.rows) &&
          resp?.error?.code <= 0
        ) {
          let rows = resp.data.rows;

          for (const item of rows) {
            let file: any;
            let originalFileName: any;
            if (item?.attachment) {
              file =
                environment.mediaUrl +
                item.attachment?.dir +
                '/' +
                item.attachment?.fileName;
              originalFileName = item.attachment?.originalFileName || 'form';
            }

            let brandMarks: any;
            if (item?.attachment) {
              brandMarks =
                environment.mediaUrl +
                item.brandMarksAttachment?.dir +
                '/' +
                item.brandMarksAttachment?.fileName;
            }

            let temp: any = {
              ...item,
              ...item.companyDetails,
              ...item.formulationDetails,
              ...item.formulationDetails?.sampleDetails,
              programName: item.programConfigDetails?.name,
              Form08ExpDate: moment(item.Form08ExpDate).format('MMMM-DD-YYYY'),
              dateSubmitted: moment(item.createdAt).format('MMMM-DD-YYYY'),
              ExpDate: moment(item.createdAt).format('MMMM-DD-YYYY'),
              fileUrl: file,
              originalFileName: originalFileName,
              BrandStatus: item.BrandStatus || 'pending',
              BrandRequestId: item.BrandRequestId,
              brandId: item.id,
              file: {
                fileUrl: file,
                originalFileName: originalFileName
              },
              brandMarksUrl: brandMarks,
              sampleID: item.formulationDetails?.sampleDetails?.id,
              brandName: item.brandName,
              uploadToWebsite: item.uploadToWebsite
                ? 'Published'
                : 'unPublished',
              createdAt: moment(item?.createdAt).format('MMMM-DD-YYYY'),
              updatedAt: moment(item?.updatedAt).format('MMMM-DD-YYYY'),
              CompanyName: item.companyDetails.CompanyName,
              CompanyNumber: item.companyDetails.companyNumber,
              // LicType (Blender / Rebrander): item,
              BrandApprovalCode: item.brandNameRegistrationId,
              LicensedBrandNames: item.brandName,
              //  Specification: item,
              //   Viscosity: item,
              Labelsapproved: item.isLabelApproved,
              ProductName: item.brandName
            };
            // Company Name, Company Number, Lic Type (Blender / Rebrander), Brand Approval Code, Licensed Brand Names, specification, viscosity, labels approved, Published on website (Y/N)
            if (item?.paymentMethod == 'card') {
              temp.paymentMethod = 'Card';
            } else if (item?.paymentMethod != 'card') {
              temp.paymentMethod = 'ACH';
            }

            retData.items.push(temp);
          }

          retData.totalCount = resp.data.count;
        } else {
          retData.error = resp?.error?.message ? resp.error.message : '';
        }

        return retData;
      })
    );
  }
  getAllBrandingList(params: any) {
    let param = this.appSettingsService.queryStringFormatWithoutOffset(params);
    return this.http.get(`brand?` + param, httpOptions).pipe(
      map((resp: any) => {
        let retData: any = {
          items: [],
          totalCount: 0,
          error: ''
        };

        if (
          resp &&
          resp.data &&
          Array.isArray(resp.data.rows) &&
          resp?.error?.code <= 0
        ) {
          let rows = resp.data.rows;

          for (const item of rows) {
            let file: any;
            let originalFileName: any;
            if (item?.attachment) {
              file =
                environment.mediaUrl +
                item.attachment?.dir +
                '/' +
                item.attachment?.fileName;
              originalFileName = item.attachment?.originalFileName || 'form';
            }
            let temp: any = {
              companyName: item.companyDetails?.name,
              programName: item.programConfigDetails?.name,
              dateSubmitted: moment(item.createdAt).format('MMMM-DD-YYYY'),
              ...item,
              brandId: item.id,
              brandName: item.brandName,
              brandNameRegistrationId: item.brandNameRegistrationId
            };
            if (item?.paymentMethod == 'card') {
              temp.paymentMethod = 'Card';
            } else if (item?.paymentMethod != 'card') {
              temp.paymentMethod = 'ACH';
            }

            retData.items.push(temp);
          }

          retData.totalCount = resp.data.count;
        } else {
          retData.error = resp?.error?.message ? resp.error.message : '';
        }

        return retData;
      })
    );
  }
  getBrandingById(id: number): Observable<any> {
    return this.http.get(`brand/${id}`).pipe(
      map((resp: any) => {
        if (
          resp &&
          resp.data &&
          Array.isArray(resp.data.rows) &&
          resp?.error?.code <= 0
        ) {
          let item = resp.data;

          let temp: any = {
            ...item
          };
          return temp;
        }
      })
    );
  }
  postAttachment(data: any) {
    return this.http.post('attachment', data).pipe(
      map(
        (resp: any) => {
          return resp.data || null;
        },
        (err: any) => {
          this.handleError(err);
        }
      )
    );
  }
  postProductLabels(data: any): Observable<any> {
    return this.http.post('productLabels', data, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }
  getproductLabelList(queryParams: any) {
    let params = this.appSettingsService.queryStrFormat(queryParams);

    return this.http.get(`productLabels?` + params, httpOptions).pipe(
      map((resp: any) => {
        let retData: any = {
          items: [],
          totalCount: 0,
          error: ''
        };

        if (
          resp &&
          resp.data &&
          Array.isArray(resp.data.rows) &&
          resp?.error?.code <= 0
        ) {
          let rows = resp.data.rows;

          for (const item of rows) {
            let file: any;
            let originalFileName: any;
            if (item?.attachment) {
              file =
                environment.mediaUrl +
                item.attachment?.dir +
                '/' +
                item.attachment?.fileName;
              originalFileName = item.attachment?.originalFileName || 'form';
            }
            let certificateAttachment: any = {};
            if (item.certificateAttachment) {
              certificateAttachment.file =
                environment.mediaUrl +
                item.certificateAttachment[0]?.dir +
                '/' +
                item.certificateAttachment[0]?.fileName;
              certificateAttachment.originalFileName =
                item.certificateAttachment[0]?.originalFileName || 'form';
            }
            let temp: any = {
              labelId: item.id,
              ...item,
              ...item.brandDetails?.companyDetails,
              ...item.brandDetails?.formulationDetails?.sampleDetails,
              ...item.brandDetails?.formulationDetails,
              ...item.brandDetails,
              sampleDetails:
                item.brandDetails?.formulationDetails?.sampleDetails,
              CompanyName: item.brandDetails?.companyDetails?.CompanyName,
              programName: item.brandDetails?.programConfigDetails?.name,
              dateSubmitted: moment(item.createdAt).format('MMMM-DD-YYYY'),
              brandName: item.brandDetails?.brandName,
              brandNameRegistrationId:
                item.brandDetails?.brandNameRegistrationId,
              attachmentUrl: file,
              certificateAttachment: certificateAttachment,
              Form08ExpDate: moment(item.brandDetails?.Form08ExpDate).format(
                'DD-MMM-YYYY'
              )
            };
            if (item?.paymentMethod == 'card') {
              temp.paymentMethod = 'Card';
            } else if (item?.paymentMethod != 'card') {
              temp.paymentMethod = 'ACH';
            }

            retData.items.push(temp);
          }

          retData.totalCount = resp.data.count;
        } else {
          retData.error = resp?.error?.message ? resp.error.message : '';
        }

        return retData;
      })
    );
  }
  getproductLabelListById(id: any) {
    return this.http.get('productLabels/' + id, httpOptions).pipe(
      map((resp: any) => {
        let item = resp.data;
        let file: any[] = [];
        let originalFileName: any;
        item.attachments.forEach((i: any) => {
          let data = {
            file: environment.mediaUrl + i.dir + '/' + i.fileName
          };
          originalFileName: i.originalFileName || 'form';
          file.push(data);
        });

        let temp: any = {
          companyName: item.brandDetails?.companyDetails?.name,
          programName: item.brandDetails?.programConfigDetails?.name,
          dateSubmitted: moment(item.brandDetails?.createdAt).format(
            'DD-MMM-YYYY'
          ),
          brandName: item.brandDetails?.brandName,
          brandNameRegistrationId: item.brandDetails?.brandNameRegistrationId,
          attachmentUrl: file,
          ...item
        };
        if (item?.paymentMethod == 'card') {
          temp.paymentMethod = 'Card';
        } else if (item?.paymentMethod != 'card') {
          temp.paymentMethod = 'ACH';
        }
        return temp;
      })
    );
  }
  updateproductLabelList(id: any, data: any): Observable<any> {
    return this.http.put('productLabels/' + id, data, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  //handle error messages
  handleError(err: any) {
    this.appSettingsService.handleError(err);
  }
  updateBrand(id: any, data: any): Observable<any> {
    return this.http.put('brand/' + id, data, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }
  onGenerateBrand(programConfigId: number, companyId: number, count: number) {
    return this.http
      .get(
        `brand/generateBrandNameRegistrationId/${programConfigId}/${companyId}?count=${count}`,
        httpOptions
      )
      .pipe(
        map((resp) => {
          return parseResponse(resp);
        })
      );
  }

  onUpdateBrand(data: any): Observable<any> {
    return this.http.put('brand', data, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }
  getApprovedLogoList(queryParams: any) {
    let params = this.appSettingsService.queryStrFormat(queryParams);

    return this.http
      .get(`brand/brandMarketingList?` + params, httpOptions)
      .pipe(
        map((resp: any) => {
          let retData: any = {
            items: [],
            totalCount: 0,
            error: ''
          };

          if (
            resp &&
            resp.data &&
            Array.isArray(resp.data.rows) &&
            resp?.error?.code <= 0
          ) {
            let rows = resp.data.rows;

            for (const item of rows) {
              let file: any;
              let originalFileName: any;
              if (item?.attachment) {
                file =
                  environment.mediaUrl +
                  item.attachment?.dir +
                  '/' +
                  item.attachment?.fileName;
                originalFileName = item.attachment?.originalFileName || 'form';
              }

              let brandMarks: any;
              let originalBrandMarksFileName: any;
              if (item?.brandMarksAttachment) {
                brandMarks =
                  environment.mediaUrl +
                  item.brandMarksAttachment?.dir +
                  '/' +
                  item.brandMarksAttachment?.fileName;
                originalBrandMarksFileName =
                  item.brandMarksAttachment?.originalFileName || 'form';
              }

              let temp: any = {
                ...item,
                brandName: item.brandName,
                fileUrl: file,
                originalFileName: originalFileName,
                BrandStatus: item.BrandStatus,
                BrandRequestId: item.BrandRequestId,
                brandId: item.id,
                file: {
                  fileUrl: brandMarks,
                  originalFileName: originalFileName
                },
                brandMarksUrl: brandMarks,
                createdAt: moment(item?.createdAt).format('MMMM-DD-YYYY'),
                updatedAt: moment(item?.updatedAt).format('MMMM-DD-YYYY'),
                BrandApprovalCode: item.brandNameRegistrationId,
                Labelsapproved: item.isLabelApproved,
                originalBrandMarksFileName: originalBrandMarksFileName
              };

              retData.items.push(temp);
            }

            retData.totalCount = resp.data.count;
          } else {
            retData.error = resp?.error?.message ? resp.error.message : '';
          }

          return retData;
        })
      );
  }
}
