import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable, map, of } from 'rxjs';
import { AppSettingsService } from 'src/app/core/app-settings.service';
import { parseResponse } from 'src/app/core/helpers';
import { environment } from 'src/assets/environments/environment.prod';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class ProgramConfigService {
  constructor(
    private http: HttpClient,
    private appSettingsService: AppSettingsService
  ) {}

  getProgramConfig(queryParams: any): Observable<any> {
    let params = this.appSettingsService.queryStringFormat(queryParams);

    return this.http.get('program-config?' + params, httpOptions).pipe(
      map((resp: any) => {
        let retData: any = {
          items: [],
          totalCount: 0,
          error: ''
        };

        if (
          resp &&
          resp.data &&
          Array.isArray(resp.data.rows) &&
          resp?.error?.code <= 0
        ) {
          let rows = resp.data.rows;
          for (const item of rows) {
            let temp: any = {
              id: parseInt(item.id),
              name: item.name,
              programNumber: item.programNumber,
              stripeConfigured: item.stripeConfigured ? 'Success' : 'Pending',
              createdAt: moment(item.createdAt).format('DD-MMM-YYYY'),
              updatedAt: moment(item.updatedAt).format('DD-MMM-YYYY')
            };
            retData.items.push(temp);
          }

          retData.totalCount = resp.data.count;
        } else {
          retData.error = resp?.error?.message ? resp.error.message : '';
        }

        return retData;
      })
    );
  }

  createProgramConfig(data: any) {
    this.programConfigCache = [];
    return this.http.post('program-config', data, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  getByProgramConfigId(id: any): Observable<any> {
    return this.http.get('program-config/' + id).pipe(
      map((resp: any) => {
        let ProgramConfig: any;
        if (resp && resp.data) {
          let attachment = resp.data?.attachment[0];
          let imageUrl =
            environment?.mediaUrl +
            attachment?.dir +
            '/' +
            attachment?.fileName;
          let imageFileName = attachment?.originalFileName;

          // let LicenseAgreementAttachment =
          //   resp.data?.licenseAgreementFormId?.attachment?.find(
          //     (i: any) => i.class == 'LicenseAgreement'
          //   );
          let licensePath = resp.data?.licenseAgreementFormId?.attachment;
          let pdfUrl =
            environment.mediaUrl +
            licensePath?.dir +
            '/' +
            licensePath?.fileName;
          let pdfFileName = licensePath?.originalFileName;

          let programLogoAttach = resp.data?.attachment?.find(
            (i: any) => i.class == 'programBrandLogo'
          );
          let logo =
            environment.mediaUrl +
            programLogoAttach?.dir +
            '/' +
            programLogoAttach?.fileName;
          let data = {
            imageUrl: imageUrl,
            imageFileName: imageFileName,
            licenseAgreementPdfUrl: pdfUrl,
            licenseAgreementPdfFileName: pdfFileName,
            logoUrl: logo,
            ...resp.data
          };
          ProgramConfig = data;
        }
        return ProgramConfig;
      })
    );
  }

  updateProgramConfig(id: any, data: any) {
    this.programConfigCache = [];
    return this.http.put('program-config/' + id, data, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  deleteProgramConfig(id: any) {
    this.programConfigCache = [];
    return this.http.delete('program-config/' + id, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  postAttachment(data: any) {
    return this.http.post('attachment', data).pipe(
      map(
        (resp: any) => {
          return resp.data || null;
        },
        (err: any) => {
          this.handleError(err);
        }
      )
    );
  }

  //handle error messages
  handleError(err: any) {
    this.appSettingsService.handleError(err);
  }

  public programConfigCache: any;
  getProgramConfigAll(): Observable<any> {
    if (
      Array.isArray(this.programConfigCache?.items) &&
      this.programConfigCache?.items?.length > 0
    ) {
      return of(this.programConfigCache); // Return cached data
    } else {
      return this.http.get('program-config', httpOptions).pipe(
        map((resp: any) => {
          let retData: any = {
            items: [],
            totalCount: 0,
            error: ''
          };

          if (
            resp &&
            resp.data &&
            Array.isArray(resp.data.rows) &&
            resp?.error?.code <= 0
          ) {
            let rows = resp.data.rows;
            for (const item of rows) {
              let attachment = item?.attachment?.find(
                (i: any) => i.class == 'ProgramConfig'
              );
              let imageUrl =
                environment.mediaUrl +
                attachment?.dir +
                '/' +
                attachment?.fileName;

              let LicenseAgreementAttachment =
                item?.licenseAgreementFormId?.attachment;
              let pdfUrl =
                environment.mediaUrl +
                LicenseAgreementAttachment?.dir +
                '/' +
                LicenseAgreementAttachment?.fileName;
              let pdfFileName = LicenseAgreementAttachment?.originalFileName;
              let sampleManagementList = JSON.parse(item.sampleManagement);
              let temp: any = {
                ...item,
                id: parseInt(item.id),
                programName: item.name,
                imageUrl: imageUrl,
                licenseAgreementForm: pdfUrl,
                sampleManagementList: sampleManagementList,
                programContentUrl: item.programContentURL,
                backgroundColor: item.backgroundColor,
                programConfigId: parseInt(item.id),
                creditCardProcessingFeePercentage:
                parseFloat(item.creditCardProcessingFeePercentage),
               financingPlanPercentage: parseFloat(item.financingPlanPercentage),
               latePaymentFeePercentage: parseFloat(item.latePaymentFeePercentage)
              };
              let bankInfo = {
                bankName: item.bankName,
                bankAddress: item.bankAddress,
                domesticAchRoutingNumber: item.domesticAchRoutingNumber,
                wireRoutingNumber: item.wireRoutingNumber,
                wireSwiftCode: item.wireSwiftCode,
                accountName: item.accountName,
                accountNumber: item.accountNumber
              };
              temp.bankInfo = bankInfo;
              retData.items.push(temp);
            }

            retData.totalCount = resp.data.count;
            this.programConfigCache = retData;
          } else {
            retData.error = resp?.error?.message ? resp.error.message : '';
          }
          return retData;
        })
      );
    }
  }

  applyLicenseAgreement(data: any) {
    return this.http.post('agreement', data).pipe(
      map(
        (resp: any) => {
          return resp.data || null;
        },
        (err: any) => {
          this.handleError(err);
        }
      )
    );
  }

  getProgramApprovalStatus(id: any, params?: any): Observable<any> {
    const paramString = params
      ? `?${this.appSettingsService.queryStringFormatWithoutOffset(params)}`
      : '';

    const url = `agreement/${id}${paramString}`;
    return this.http.get(url, httpOptions).pipe(
      map((resp: any) => {
        let retData: any = {
          items: [],
          totalCount: 0,
          error: ''
        };

        if (
          resp &&
          resp.data &&
          Array.isArray(resp.data.rows) &&
          resp?.error?.code <= 0
        ) {
          let rows = resp.data.rows;
          for (const item of rows) {
            let attachment = item.attachment.find(
              (i: any) => i.class == 'AgreementByFQ'
            );
            let agreementForm =
              environment.mediaUrl +
              attachment?.dir +
              '/' +
              attachment?.fileName;
            let temp: any = {
              agreementForm: agreementForm,
              ...item,
              licenseFee: parseFloat(item.LicenseFee),
              LicenseFee: parseFloat(item.LicenseFee),
              name: item.type,
              date: moment(item.createdAt).format('DD-MMM-YYYY'),
              uploadedFile: agreementForm,
              LicenseRenewalFee: parseFloat(item.LicenseRenewalFee)
            };
            retData.items.push(temp);
          }

          retData.totalCount = resp.data.count;
        } else {
          retData.error = resp?.error?.message ? resp.error.message : '';
        }

        return retData;
      })
    );
  }

  getAgreementStatus(
    id: any,
    type: string,
    companyId: string,
    programConfigId: string
  ): Observable<any> {
    return this.http
      .get(
        `agreement/retrive/${id}?type=${type}&companyId=${companyId}&programConfigId=${programConfigId}`,
        httpOptions
      )
      .pipe(
        map((resp: any) => {
          let retData: any;
          if (resp && resp.data) {
            let item = resp.data;
            let attachment = item.attachment.find(
              (i: any) => i.class == 'AgreementByFQ'
            );
            let agreementForm =
              environment.mediaUrl +
              attachment?.dir +
              '/' +
              attachment?.fileName;
            let temp: any = {
              agreementForm: agreementForm,
              ...item
            };
            retData = temp;
          }

          return retData;
        })
      );
  }

  createCompanyContactInfo(data: any) {
    this.programConfigCache = [];
    return this.http.post('contactInformation', data, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }
}
