import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { environment } from 'src/assets/environments/environment';
import { StorageMap } from '@ngx-pwa/local-storage';
import { takeUntil, timeout, map, catchError, switchMap } from 'rxjs/operators';
import { parseResponse } from '../../core/helpers';
import { finalize } from 'rxjs/operators';
import { LoaderService } from './loader.service';
import { Router } from '@angular/router';
import { AppSettingsService } from '../app-settings.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  private apiUrl = environment.apiUrl;

  private jwtToken: any;
  private openURLs: Array<string> = ['/users/login', '/forgot_password'];
  private defaultTimeout = 60;
  private excludedUrlsRegex: RegExp[];
  private excludedUrls = ['.svg', 'json'];

  constructor(
    private localStorage: StorageMap,
    public loaderService: LoaderService,
    private router: Router,
    private appSettingsService: AppSettingsService
  ) {
    this.excludedUrlsRegex =
      this.excludedUrls.map((urlPattern) => new RegExp(urlPattern, 'i')) || [];
  }

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const passThrough: boolean = !!this.excludedUrlsRegex.find((regex) =>
      regex.test(req.url)
    );

    // Skip adding headers for excluded URLs or S3 requests
    if (req.url.includes('s3.us-west-2.amazonaws')) {
      return next.handle(req);
    }
    if (passThrough) {
      return next.handle(req);
    }

    this.loaderService.isLoading.next(true);
    if (this.openURLs.indexOf(req.url) > -1) {
      this.jwtToken = undefined;
    }

    let skipLoader = false;
    if (!req.headers.has('skipLoader')) {
      // this.spinner.show();
    } else {
      skipLoader = true;
      req = req.clone({
        headers: req.headers.delete('skipLoader')
      });
    }

    // if (this.jwtToken) {
    //   return this.prepareUrlAndHeaders(req, next, skipLoader);
    // }

    return from(this.localStorage.get('ng-token')).pipe(
      switchMap((jwtToken) => {
        this.jwtToken = jwtToken;
        return this.prepareUrlAndHeaders(req, next, skipLoader);
      })
    );
  }

  private prepareUrlAndHeaders(
    req: HttpRequest<any>,
    next: HttpHandler,
    skipLoader: boolean = false
  ): Observable<HttpEvent<any>> {
    req = req.clone({
      url:
        req.url.indexOf('http://') === 0 || req.url.indexOf('https://') === 0
          ? req.url
          : this.apiUrl + req.url,
      setHeaders: {
        platform: 'pos',
        Authorization: `Bearer ${this.jwtToken}`
      }
    });

    // Make request
    return next.handle(req).pipe(
      timeout(1000 * this.defaultTimeout),
      map((event) => {
        if (event instanceof HttpResponse) {
          if (!skipLoader) {
            setTimeout(() => {
              /** spinner ends after 5 seconds */
              // this.spinner.hide();
            }, 1000 * 1);
          }
        }
        // if (
        //     event instanceof HttpResponse &&
        //     event.body &&
        //     event.body.data &&
        //     event.body.data.count === 0
        // ) {
        //     // this.router.navigate(['/empty-state']);
        //     this.toastr.error('No Result Found');
        // }
        // if (
        //     event instanceof HttpResponse &&
        //     event.body &&
        //     event.body.data &&
        //     event.body.data.count === 0
        //   ) {
        //     // Show toastr message only if it hasn't been shown before
        //     if (!this.toastr.shown) {
        //       // this.router.navigate(['/empty-state']);
        //       this.toastr.error('No Result Found');
        //       this.toastr.shown = true; // Set the variable to true after showing the message
        //     }
        //   }

        return event;
      }),

      catchError((error: HttpErrorResponse) => {
        console.log('http-errror', error);
        if (error.status === 401) {
          this.localStorage.delete('ng-token');
          localStorage.removeItem('role');
          this.appSettingsService.setUserDispensary(null);
          this.appSettingsService.setUserSettings(null);
        }
        if (error.status == 502) {
          this.router.navigate(['/error/500']);
          // this.router.navigate(['/empty-state']);
        }
        return throwError(() => parseResponse(error.error));
      }),
      finalize(() => {
        this.loaderService.isLoading.next(false);
      })
    );
  }
}
